// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-animation-gallery-js": () => import("./../../../src/pages/animation-gallery.js" /* webpackChunkName: "component---src-pages-animation-gallery-js" */),
  "component---src-pages-illustration-gallery-js": () => import("./../../../src/pages/illustration-gallery.js" /* webpackChunkName: "component---src-pages-illustration-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-profile-cards-js": () => import("./../../../src/pages/profile-cards.js" /* webpackChunkName: "component---src-pages-profile-cards-js" */),
  "component---src-templates-animation-js": () => import("./../../../src/templates/animation.js" /* webpackChunkName: "component---src-templates-animation-js" */),
  "component---src-templates-chapter-js": () => import("./../../../src/templates/chapter.js" /* webpackChunkName: "component---src-templates-chapter-js" */),
  "component---src-templates-illustration-js": () => import("./../../../src/templates/illustration.js" /* webpackChunkName: "component---src-templates-illustration-js" */),
  "component---src-templates-person-profile-js": () => import("./../../../src/templates/personProfile.js" /* webpackChunkName: "component---src-templates-person-profile-js" */),
  "component---src-templates-section-js": () => import("./../../../src/templates/section.js" /* webpackChunkName: "component---src-templates-section-js" */)
}

