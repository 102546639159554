module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-axe/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"duty-to-country-exhibit","short_name":"dtc","start_url":"/","background_color":"#05090a","theme_color":"#9d171e","display":"standalone","icon":"src/images/dtc-icon-192x192.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"766d7591f8a9f6eb6026d9b71d3c593b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-7GT9RT5BTY"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":false,"respectDNT":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
